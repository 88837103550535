import { axiosApi } from "../axiosApi";

export const getProjects = async (isPaginated = false, pagination = null, columnFilters = null, sorting = null) => {
    try {
        const URL_PROJECTS = new URL(`${import.meta.env.VITE_REACT_APP_URL}/proyecto/listar`);
        if (isPaginated) {
            URL_PROJECTS.searchParams.set('isPaginated', isPaginated ? '1' : null);
            URL_PROJECTS.searchParams.set('perpage', `${pagination.pageSize}`);
            URL_PROJECTS.searchParams.set('page', `${pagination.pageIndex + 1}`);
            URL_PROJECTS.searchParams.set('filters', columnFilters.length > 0 ? JSON.stringify(columnFilters) : '');
            URL_PROJECTS.searchParams.set('sortBy', sorting.length > 0 ? sorting[0].id : '');
            URL_PROJECTS.searchParams.set('sortOrder', sorting.length > 0 ? (sorting[0].desc ? 'desc' : 'asc') : '');
        }
        const response = await axiosApi.get(URL_PROJECTS);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getProjectsOrder = async () => {
    try {
        const response = await axiosApi.get(`/api/proyecto/listar-order`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getProject = async (project) => {
    try {
        const response = await axiosApi.get(`/api/proyecto/${project}`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const editProject = async (project) => {
    try {
        const response = await axiosApi.put(
            `/api/proyecto/actualizar`,
            project
        );
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const cloneProject = async (project) => {
    try {
        const response = await axiosApi.post(`/api/proyecto/duplicar`, project);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const createProject = async (project) => {
    try {
        const { data } = await axiosApi.post("/api/proyecto/crear", project);
        return data;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getPanelists = async (url) => {
    try {
        const response = await axiosApi.get(url);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getPanelist = async (id, token) => {
    try {
        const { data } = await axiosApi.get(
            `/api/proyecto/${id}/panelista/${token}`
        );
        return data;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const resetPanelistSurvey = async (panelist) => {
    try {
        const { data } = await axiosApi.put(
            `/api/proyecto/${panelist.project_id}/panelista/${panelist.token}/reiniciar`,
            panelist
        );
        return data;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const updatePanelistsExpiredAtByPanel = async (panels) => {
    try {
        const { data } = await axiosApi.put(
            `/api/proyecto/${panels.project_id}/panelistas/actualizar-fecha-expiracion`,
            panels
        );
        return data;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const editPanelist = async (panelist) => {
    try {
        const { data } = await axiosApi.put(
            `/api/proyecto/${panelist.project_id}/panelista/${panelist.token}/editar`,
            panelist
        );
        return data;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const createLinks = async (formData) => {
    try {
        const { data } = await axiosApi.post(
            `/api/proyecto/generar-links`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
        );
        return data;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const expireProjectPanelist = async (panelist) => {
    try {
        const response = await axiosApi.put(
            `/api/proyecto/${panelist.project_id}/panelista/expirar`,
            panelist
        );
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const deleteProjectPanelistTest = async (id) => {
    try {
        const response = await axiosApi.delete(
            `/api/proyecto/${id}/panelistas/eliminar/pruebas`
        );
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const resetProjectPanelistTest = async (id) => {
    try {
        const response = await axiosApi.put(
            `/api/proyecto/${id}/panelistas/reiniciar/pruebas`
        );
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const addExternalLink = async (data) => {
    try {
        const response = await axiosApi.put(`/api/proyecto/agregar-link`, data);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};
export const getPotencialBaseDescription = async (id) => {
    try {
        const response = await axiosApi.get(
            `/api/proyecto/${id}/base-potencial-descripcion`
        );
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getSurveyAvailableParameters = async (id) => {
    try {
        const response = await axiosApi.get(`/api/proyecto/${id}/parametros`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const createPanelExternalConfig = async (panelExternalConfigData) => {
    const url = "/api/proyecto/crear/panelexternalconfig";
    try {
        const { data } = await axiosApi.post(url, panelExternalConfigData);
        return data;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const editPanelExternalConfig = async (panelExternalConfigData) => {
    try {
        const response = await axiosApi.put(
            `/api/proyecto/actualizar/panel-externo`,
            panelExternalConfigData
        );
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getItemsProject = async (id, hash) => {

    var url = `/api/proyecto/${id}/items-basico`;
    if (hash) {
        url = `/api/cliente/monitor/h/${hash}/p/${id}/items-basico`
    }

    try {
        const response = await axiosApi.get(`${url}`);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getDailyProject = async (id, hash) => {
    var url = `/api/proyecto/${id}/avance-diario`;
    if (hash) {
        url = `/api/cliente/monitor/h/${hash}/p/${id}/avance-diario`;
    }
    try {
        const response = await axiosApi.get(url);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getPanelExternalProject = async (id, hash) => {
    var url = `/api/proyecto/${id}/panel-externo`;
    if (hash) {
        url = `/api/cliente/monitor/h/${hash}/p/${id}/panel-externo`;
    }
    try {
        const response = await axiosApi.get(url);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getQuotasProject = async (id, hash) => {
    var url = `/api/proyecto/${id}/cuotas`;
    if (hash) {
        url = `/api/cliente/monitor/h/${hash}/p/${id}/cuotas`;
    }
    try {
        const response = await axiosApi.get(url);
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const deletePanelExternalConfig = async (sioPanelExternal) => {
    try {
        const response = await axiosApi.delete(
            `/api/proyecto/${sioPanelExternal.pivot.project_id}/eliminar/${sioPanelExternal.id}/panel-externo`
        );
        return response;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getAllPanelExternalConfig = async (id) => {
    const { data } = await axiosApi.get(
        `/api/proyecto/${id}/listar/paneles-externos`
    );
    return data;
};

export const setProjectsOrder = async (projects) => {
    const { data } = await axiosApi.put(`/api/proyecto/ordenar-proyectos`, projects);
    return data;
};

export const getAssociatedPanels = async (projectId) => {
    const response = await axiosApi.get(`/api/proyecto/${projectId}/paneles-agregado`);
    return response;
}

export const exportMetricsPanel = async (row) => {
    const response = await axiosApi.get(`/api/proyecto/${row.projectId}/exportar/metricas/panel/${row.panel}`);
    return response;
}

export const checkAttribute14 = async (id) => {
    try {
        const data = await axiosApi.get(`/api/proyecto/check/attribute-14/${id}`);
        return data;
    } catch (error) {
        const { response } = error;
        throw response;
    }
};

export const getTrackerInformation = async (id) => {
    try {
        const data = await axiosApi.get(`/api/proyecto/${id}/tracker`);
        return data;
    } catch (error) {
        const { response } = error;
        throw response;
    }
}