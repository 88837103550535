// React
import React, { useRef, useCallback, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { App } from "../../../App";

// Componente de terceros
import { SidebarRight } from "./SidebarRight";
import { useMediaQuery } from "react-responsive";

//Componentes y hooks del proyecto
import { PanelistCreate } from "./modals/PanelistCreate";
import { useCheckAttr14, useProject } from "../../../hooks/useProject";
import { PanelistEdit } from "./modals/PanelistEdit";
import { useState } from "react";
import { getPanelists } from "../../../api/sio/project";
import { PanelistsTableProject } from "./table/PanelistsTableProject";
import { useGetSurvey } from "../../../hooks/useSurvey";
import { Alert, AlertTitle } from "@mui/material";

export const Panelists = ({ id }) => {

    const mediaQuery = useMediaQuery({ query: "(min-width:1000px)" });
    const { survey, isLoading: isLoadingSurvey, isError: isErrorSurvey, error: errorSurvey } = useGetSurvey(id);
    const { errors, isLoading: isLoadingCheckAttr14 } = useCheckAttr14(id);

    const debouncedRef = useRef(1000);
    const [isLoading, setIsLoading] = useState(false);
    const [panelists, setPanelists] = useState([]);
    const [url, setUrl] = useState("");
    const [rowCount, setRowCount] = useState();
    const [columnFilters, setColumnFilters] = useState([]);
    const [seeModal, setSeeModal] = useState({ create: false, edit: false, updateExpiredAt: false });
    const [isError, setIsError] = useState({ status: false, message: "" });
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [token, setToken] = useState("");
    const [remountTable, setRemountTable] = useState(false);

    /**
     *  state paginations
     */
    const { project } = useProject(id);

    const handlePanelists = useCallback(async () => {
        setIsLoading(true);
        var _url = new URL(`${import.meta.env.VITE_REACT_APP_URL}/proyecto/${id}/panelistas`);
        _url.searchParams.set('perpage', `${pagination.pageSize}`);
        _url.searchParams.set('page', pagination.pageIndex + 1);
        _url.searchParams.set('filters', columnFilters.length > 0 ? JSON.stringify(columnFilters) : '');
        setUrl(_url);
        try {
            let response = await getPanelists(_url);
            const { data, status } = response;
            if (status == 204) {
                setPanelists([]);
                setIsError({
                    status: true,
                    message: "No hay datos disponibles"
                })
            } else {
                setPanelists(data.data);
                setRowCount(data.total);
                setIsError({
                    status: false,
                    message: ""
                })
            }
        } catch (error) {
            const { data, status } = error;
            if (status == 422) {
                setIsError({
                    status: true,
                    message: data.message
                })
            } else {
                setIsError({
                    status: true,
                    message: "Error al cargar los datos"
                })
            }
        }
        setIsLoading(false);
    }, [panelists, pagination, columnFilters]);

    useEffect(() => {
        setIsLoading(true);
        if (debouncedRef != undefined) {
            clearTimeout(debouncedRef.current);
        }

        debouncedRef.current = setTimeout(() => {
            handlePanelists()
        }, 1500);
    }, [
        pagination.pageIndex, pagination.pageSize, columnFilters
    ]);


    return (
        <>
            {seeModal.create && (
                <PanelistCreate
                    seeModal={seeModal}
                    setSeeModal={setSeeModal}
                    project={project}
                    refetch={handlePanelists}
                    setRemountTable={setRemountTable}
                />
            )}

            {seeModal.edit && (
                <PanelistEdit
                    seeModal={seeModal}
                    setSeeModal={setSeeModal}
                    project={project}
                    token={token}
                    refetch={handlePanelists}
                />
            )}

            <div className="row">
                {!mediaQuery && !isLoadingSurvey && <SidebarRight id={id} survey_status={survey.ls_status} />}
                <div className="col-xl-9 col-lg-9 mt-4">
                    <h4>Proyecto: {project.name}</h4>
                    <div className="card mb-4">
                        <div className="card-header">
                            <div className="content-head-card">
                                <label>Panelistas</label>
                                <a
                                    className="btn btn-primary-oo"
                                    onClick={() => setSeeModal({ ...seeModal, create: !seeModal.create })}
                                >
                                    Crear panelistas
                                </a>
                            </div>
                        </div>
                        {/* Card Body */}
                        <div className="card-body">
                            {isErrorSurvey && <Alert severity="error" sx={{ marginTop: '10px', marginBottom: '10px' }}>{errorSurvey}</Alert>}
                            {!isLoadingCheckAttr14 && errors.status == 'error'
                                && (<Alert severity="error" sx={{ marginTop: '10px', marginBottom: '10px' }}>
                                    {
                                        errors['attribute_14']['status'] == 'no_attribute_14' && (
                                            <AlertTitle>Este proyecto no tiene configurado el attribute_14 (segment_status).</AlertTitle>
                                        )
                                    }
                                    {
                                        errors['tracker']['status'] == 'no_platform_tracker' && (
                                            <AlertTitle>Este proyecto no tiene configurado la opción tracker en la integración de panel</AlertTitle>
                                        )
                                    }
                                    <hr></hr>Solicitar a TI ajustar la programación de la encuesta.
                                </Alert>)
                            }
                            {!survey.ls_status &&
                                <Alert severity="error" sx={{ marginTop: '10px', marginBottom: '10px' }}>
                                    <AlertTitle>Encuesta deshabilitada en Limesurvey</AlertTitle>
                                    <hr></hr>
                                    <p>La programación de este cuestionario no está habilitada en <b>Limesurvey</b></p>
                                    <p>Posible motivo: ajustes en programación de cuestionario.</p>
                                    <p>Consultar a TI para más información.</p>
                                </Alert>
                            }
                            {
                                <PanelistsTableProject
                                    projectId={id}
                                    panelists={panelists}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    rowCount={rowCount}
                                    columnFilters={columnFilters}
                                    setColumnFilters={setColumnFilters}
                                    url={url}
                                    setUrl={setUrl}
                                    isError={isError}
                                    setIsError={setIsError}
                                    isLoading={isLoading}
                                    refetch={handlePanelists}
                                    seeModal={seeModal}
                                    setSeeModal={setSeeModal}
                                    token={token}
                                    setToken={setToken}
                                    remountTable={remountTable}
                                    setRemountTable={setRemountTable}
                                />
                            }
                        </div>
                    </div>
                </div>
                {mediaQuery && !isLoadingSurvey && <SidebarRight id={id} survey_status={survey.ls_status} />}
            </div>
        </>
    );
};

if (document.getElementById("jsx-links-test-project")) {
    const el = document.getElementById("jsx-links-test-project");
    const Index = ReactDOM.createRoot(el);
    const props = Object.assign({}, el.dataset);
    Index.render(
        <App>
            <Panelists {...props} />
        </App>
    );
}
