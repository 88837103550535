import React, { useState } from "react";
import Select from "react-select";
import { usePanelExternalsByProject } from "../../../../hooks/usePanelExternal";
import TextField from "@mui/material/TextField";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import de from "date-fns/locale/de";
import { createLinks } from "../../../../api/sio/project";
import { useMutation } from "react-query";
import Swal from "sweetalert2";
import { LoadExternalTokens } from "../externaltokens/LoadExternalTokens";
import { useLoadExternalTokens } from "../../../../store/ExternalTokens/useLoadExternalTokens";
import { CircularProgress } from "@mui/material";
import { usePanels } from "../../../../hooks/useProject";

export const PanelistCreate = ({ seeModal, setSeeModal, project, refetch, setRemountTable }) => {
    const [formState, setFormState] = useState({
        project_id: project.id,
        quantity: 10,
        expired_at: new Date(),
        panel: "TEST",
        externalLinks: project.externalLinks
    });

    const { file, resetFile, isProcessingFile, setIsProcessingFile, addFileToFormData } = useLoadExternalTokens((state) => state);

    const { getPanels } = usePanels(project.id);

    const { panelExternalConfigs } = usePanelExternalsByProject(project.id);
    const panelOptions = [
        ...panelExternalConfigs.map((x) => {
            return {
                value: x.name,
                label: x.name,
            };
        }),
        {
            value: "TEST",
            label: "PRUEBA",
        },
    ];

    //create panelist
    const {
        mutate: mCreatePanelist,
        isError: isErrorCreatePanelist,
        error: errorCreatePanelist,
    } = useMutation(createLinks, {
        onSuccess: () => {
            Swal.fire(
                "Crear Panelistas",
                `Se generaron ${formState.quantity} Panelistas para el panel ${formState.panel}`,
                "success"
            ).then(() => {
                setIsProcessingFile(false);
                setSeeModal({
                    ...seeModal,
                    create: !seeModal.create,
                });
                if(project.externalLinks == 'required'){ resetFile(); }
                refetch();
                setRemountTable(true);
            });
        },
        onError: ({ data }) => {
            setIsProcessingFile(false);
            Swal.fire(
                "Ha ocurrido un error",
                `${ data.message || 'Ha ocurrido un error, no se han añadido panelistas' }`,
                "error"
            );
        },
    });

    //OnSubmit handler
    const handleOnSubmit = (e) => {
        e.preventDefault();
        Swal.fire({
            title: `¿Quieres crear ${formState.quantity} panelistas de ${
                formState.panel === "TEST" ? "PRUEBA" : formState.panel
            }?`,
            showCancelButton: true,
            confirmButtonText: "Crear",
            cancelButtonText: "Cancelar",
            icon: "info",
        }).then((result) => {
            if(result.isConfirmed) {
                setIsProcessingFile(true);
                const _formState = addFileToFormData(file,formState);
                if(_formState && formState.externalLinks == 'required') {
                    mCreatePanelist(_formState);
                }else {
                    mCreatePanelist(formState);
                }
            }else {
                setIsProcessingFile(false);
                Swal.fire(
                    "Crear Panelistas",
                    "No se han añadido panelistas",
                    "error"
                );
            }
        });
    };

    return (
        <>
            <div className="modal modal-segmentation">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Crear panelistas</h5>
                            <button
                                className="close close-modal-btn"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setSeeModal({
                                        ...seeModal,
                                        create: !seeModal.create,
                                    });
                                    if(project.externalLinks == 'required'){ resetFile(); }
                                }}
                            >
                                <i className="fa fa-times-circle"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={(e) => handleOnSubmit(e)}>
                                <div className="row mb-3">
                                    <div className="form-group col-md-6">
                                        <label className="my-1 fw-bold">
                                            Seleccionar un panel
                                        </label>
                                        <Select
                                            name="panel"
                                            isMulti={false}
                                            className="basic-multi-select"
                                            options={panelOptions}
                                            defaultValue={{
                                                value: "TEST",
                                                label: "PRUEBA",
                                            }}
                                            onChange={(e) => {
                                                setFormState({
                                                    ...formState,
                                                    panel: e.value,
                                                });
                                            }}
                                        />
                                        {isErrorCreatePanelist && (
                                            <p className="text text-danger">
                                                {errorCreatePanelist.data.errors?.panel?.map(
                                                    (x) => x
                                                )}
                                            </p>
                                        )}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className="my-1 fw-bold">
                                            Cantidad
                                        </label>
                                        <input
                                            className="form-control"
                                            type={"number"}
                                            value={formState.quantity}
                                            onChange={(e) =>
                                                setFormState({
                                                    ...formState,
                                                    quantity:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                        {isErrorCreatePanelist && (
                                            <p className="text text-danger">
                                                {errorCreatePanelist.data.errors?.quantity?.map(
                                                    (x) => x
                                                )}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label className="my-1 fw-bold">
                                            Fecha de expiración
                                        </label>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            adapterLocale={de}
                                        >
                                            <DateTimePicker
                                                ampm={false}
                                                className="form-control"
                                                inputFormat="dd/MM/yyyy HH:mm"
                                                value={formState.expired_at}
                                                onChange={(e) => {
                                                    setFormState({
                                                        ...formState,
                                                        expired_at: e,
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        size="small"
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                        {isErrorCreatePanelist && (
                                            <p className="text text-danger">
                                                {errorCreatePanelist.data.errors?.expired_at?.map(
                                                    (x) => x
                                                )}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                { project.externalLinks == 'required' &&
                                    <div className="row">
                                        <LoadExternalTokens
                                            formState={formState}
                                        />
                                        {isErrorCreatePanelist && (
                                            <p className="text text-danger">
                                                {errorCreatePanelist.data.errors?.tokens_file?.map(
                                                    (x) => <p>{x}</p>
                                                )}
                                            </p>
                                        )}
                                    </div>
                                }
                                <hr />
                                <div className="form-group col-md-12">
                                    <button
                                        type="submit"
                                        className="btn btn-primary-oo"
                                    >
                                        { isProcessingFile ? (<div className="d-flex align-items-center justify-content-center">{'Procesando...'}<CircularProgress sx={{ marginLeft: "5px", color: "#fff" }} size={'1rem'} /></div>) : 'Crear Panelistas' }
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
